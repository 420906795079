import { useState } from "react";
import { Checkbox } from "semantic-ui-react";
import EntitySearchSelect from "../EntitySearchSelect";

const EntityAvailability = ({ club, onChange }) => {
    const [usingShow, setUsingShow] = useState(club.entitiesShow.length > 0);
    const [usingHide, setUsingHide] = useState(club.entitiesHide.length > 0);

    const getEntityList = () => {
        if (club.entitiesShow.length > 0) {
            return club.entitiesShow;
        }

        return club.entitiesHide;
    };

    const confirmDisable = () => {
        return window.confirm(
            "Turning off this toggle will remove all entities from the list. Please confirm you want to continue.",
        );
    };

    const handleChangeHideToggle = (value) => {
        if (
            value === false &&
            club.entitiesHide.length > 0 &&
            confirmDisable() === false
        ) {
            return;
        }

        setUsingHide(value);
        if (value === true) {
            setUsingShow(false);
            // If switching to hide, copy show values to hide
            return onChange([], club.entitiesShow);
        }

        onChange([], []);
    };

    const handleChangeShowToggle = (value) => {
        if (
            value === false &&
            club.entitiesShow.length > 0 &&
            confirmDisable() === false
        ) {
            return;
        }

        setUsingShow(value);
        if (value === true) {
            setUsingHide(false);
            // If switching to hide, copy show values to hide
            return onChange(club.entitiesHide, []);
        }

        onChange([], []);
    };

    const onChangeEntities = (value) => {
        if (usingShow === true) {
            return onChange(value, []);
        }

        onChange([], value);
    };

    return (
        <>
            <h4>Entity availability</h4>
            <p className='mt-3 mb-3'>
                Define which entities are able to see this club within the app
            </p>
            <Checkbox
                label='Only show this club for the below entities'
                toggle
                checked={usingShow}
                onChange={() => {
                    handleChangeShowToggle(usingShow === false);
                }}
            />
            <Checkbox
                className='ml-5'
                label='Hide this club from the below entities'
                toggle
                checked={usingHide}
                onChange={() => {
                    handleChangeHideToggle(usingHide === false);
                }}
            />

            {(usingShow || usingHide) && (
                <EntitySearchSelect
                    className='mt-3'
                    placeholder='Select entities'
                    value={getEntityList()}
                    onChange={onChangeEntities}
                    active
                    multiple
                    fluid
                />
            )}
        </>
    );
};

export default EntityAvailability;
