import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { format, parse } from "date-fns";
import { Input } from "semantic-ui-react";

const DatePicker = ({
    value,
    onDayChange,
    dayPickerProps = {},
    disabled = false,
    className = "",
    label = null,
}) => {
    const inputProps = {
        readOnly: true,
        disabled,
        className,
    };

    if (label !== null) {
        inputProps.label = label;
    }

    return (
        <DayPickerInput
            placeholder='Select date'
            component={React.forwardRef((props, ref) => {
                return <Input ref={ref} {...props} />;
            })}
            format='DD/MM/YYYY'
            value={format(value, "dd/MM/yyyy")}
            dayPickerProps={dayPickerProps}
            onDayChange={onDayChange}
            inputProps={inputProps}
            formatDate={(date) => format(date, "dd/MM/yyyy")}
            parseDate={(dateString) => parse(dateString, "dd/MM/yyyy", value)}
        />
    );
};

export default DatePicker;
