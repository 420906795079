import React, { Component } from "react";
import { Select } from "semantic-ui-react";
import GolfClub from "../../models/GolfClub";

class GolfClubSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubs: [],
            term: "",
            existingClubs: [],
            searchTimer: null,
        };
    }

    async componentDidMount() {
        if ((this.props.value ?? null) === null) return;
        const include = Array.isArray(this.props.value)
            ? this.props.value
            : [this.props.value];
        if (include.length === 0) return;
        const clubs = await GolfClub.list({
            include,
        });
        this.setState({ existingClubs: clubs, clubs });
    }

    async getClubs() {
        const additionalQueryParams = {};
        if (this.props.active === true) {
            additionalQueryParams.active = true;
        }

        if (this.props.acceptsCredit === true) {
            additionalQueryParams.acceptsCredit = true;
        }

        if (this.props.hideNotVisible === true) {
            additionalQueryParams.hideNotVisible = true;
        }
        if (typeof this.props.memberCanAccess === "string") {
            additionalQueryParams.memberCanAccess = this.props.memberCanAccess;
        }
        const { term } = this.state;
        let exclude = this.props.value;
        if (Array.isArray(exclude) === false) {
            exclude = [exclude];
        }

        return GolfClub.list(
            Object.assign({ term, exclude }, additionalQueryParams),
        );
    }

    onChangeSearch(value) {
        this.setState({ term: value });

        if (this.isLoading) {
            clearTimeout(this.state.searchTimer);
        }

        if (value.length === 0) {
            return this.setState({ searchTimer: null });
        }

        const searchTimer = setTimeout(async () => {
            const clubs = await this.getClubs();
            clearTimeout(this.state.searchTimer);
            return this.setState({
                searchTimer: null,
                clubs: clubs.concat(this.state.existingClubs),
            });
        }, 1000);

        this.setState({ searchTimer });
    }

    emptyListMessage() {
        if (this.isLoading) {
            return "Searching...";
        }

        if (this.state.term.length === 0) {
            return "Type to search for clubs";
        }

        return "No clubs found";
    }

    onChange(value) {
        const compareValue = Array.isArray(value) ? value : [value];
        const newExisting = [];
        for (const id of compareValue) {
            const existing = newExisting.some(({ _id }) => _id === id);
            if (existing === false) {
                const add =
                    this.state.clubs.find(({ _id }) => _id === id) ?? null;
                newExisting.push(add);
            }
        }

        this.setState({
            term: "",
            existingClubs: newExisting,
            clubs: newExisting,
        });
        this.props.onChange(value);
    }

    get isLoading() {
        return this.state.searchTimer !== null;
    }

    dropdownOptions() {
        const options = this.state.clubs.map((club, i) => ({
            key: i,
            text: `${club.name}`,
            value: club._id,
        }));

        return options;
    }

    render() {
        return (
            <Select
                placeholder={this.props.placeholder ?? "Search for clubs..."}
                icon='search'
                selection
                loading={this.isLoading}
                value={this.props.value}
                onSearchChange={({ target }) =>
                    this.onChangeSearch(target.value)
                }
                onChange={(_, props) => this.onChange(props.value)}
                search={(i) => i}
                multiple={this.props.multiple}
                options={this.dropdownOptions()}
                noResultsMessage={this.emptyListMessage()}
                disabled={this.props.disabled ?? false}
                fluid={this.props.fluid ?? false}
                className={"pr-2 " + this.props.className ?? ""}
            />
        );
    }
}

export default GolfClubSearchSelect;
