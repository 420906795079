import React, { useState, useEffect } from "react";
import {
    Form,
    Modal,
    TextArea,
    Segment,
    Button,
    Dropdown,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import BookingReview from "../../../models/BookingReview";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import LoadingPlaceholder from "../../../assets/components/LoadingPlaceholder";
import StarSelectDisplay from "../../../assets/components/StarSelectDisplay";

const Show = ({ history, match }) => {
    const [review, setReview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("POOR_QUALITY");
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    const rejectionReasons = [
        {
            key: 0,
            text: "Contains inappropriate or harmful content",
            value: "HARMFUL",
        },
        {
            key: 1,
            text: "Contains spam",
            value: "SPAM",
        },
        {
            key: 2,
            text: "Contains poor quality or unreadable content",
            value: "POOR_QUALITY",
        },
    ];

    useEffect(() => {
        const loadReview = async () => {
            try {
                const response = await BookingReview.show(match.params.id);
                setReview(response);
            } catch (_) {
                setError(true);
            }
            setLoading(false);
        };
        loadReview();
    }, []);

    const approve = async () => {
        setShowApproveModal(false);
        setLoading(true);
        try {
            await review.approve();
            review.approvedAt = new Date();
            review.status = "APPROVED";
            setReview(new BookingReview(review));
        } catch (_) {
            setError(true);
        }
        setLoading(false);
    };

    const reject = async () => {
        setShowRejectModal(false);
        setLoading(true);
        try {
            await review.reject({ reason: rejectionReason });
            review.rejectedAt = new Date();
            review.rejectedReason = rejectionReason;
            review.status = "REJECTED";
            setReview(new BookingReview(review));
        } catch (_) {
            setError(true);
        }
        setLoading(false);
    };

    const actions = () => {
        if (review.status === "APPROVED") {
            return (
                <p>
                    This review was approved on the{" "}
                    {format(review.approvedAt, "do 'of' LLLL yyyy")}
                </p>
            );
        }
        if (review.status === "REJECTED") {
            return (
                <p>
                    This review was rejected on the{" "}
                    {format(review.rejectedAt, "do 'of' LLLL yyyy")}:{" "}
                    {review.rejectedReason}
                </p>
            );
        }

        if (review.status === "EXPIRED") {
            return (
                <p>
                    This review expired on the{" "}
                    {format(review.rejectedAt, "do 'of' LLLL yyyy")}
                </p>
            );
        }

        return (
            <>
                <Button
                    primary
                    className='theme mt-4'
                    onClick={() => setShowApproveModal(true)}
                    disabled={false}
                >
                    Approve review
                </Button>
                <Button
                    primary
                    className='red'
                    onClick={() => setShowRejectModal(true)}
                    disabled={false}
                >
                    Reject review
                </Button>
            </>
        );
    };

    const clubReview = () => {
        return review.clubReview ? (
            <Link to={`/reviews/clubs/${review.clubReview._id}`}>View</Link>
        ) : (
            "N/A"
        );
    };

    const content = () => {
        if (loading === true) {
            return <LoadingPlaceholder fluid />;
        }

        if (error === true) {
            return <h2>Something went wrong.</h2>;
        }

        return (
            <>
                <div className='row skinny'>
                    <div className='col'>
                        <h4>
                            Review type:{" "}
                            {review.reviewType
                                ? review.reviewType.charAt(0) +
                                  review.reviewType
                                      .substring(1)
                                      .toLowerCase()
                                      .replaceAll("_", " ")
                                : "Unknown"}
                        </h4>
                        <h4>
                            Event:{" "}
                            {review.eventBooking ? (
                                <Link
                                    to={`/events/${review.eventBooking.event._id}`}
                                >
                                    {review.eventBooking.event.name}
                                </Link>
                            ) : (
                                "N/A"
                            )}
                        </h4>
                        <h4>
                            Club:{" "}
                            {review.clubReview ? (
                                <Link
                                    to={`/golf-clubs/${review.clubReview.club._id}`}
                                >
                                    {review.clubReview.club.name}
                                </Link>
                            ) : (
                                "N/A"
                            )}
                        </h4>
                        <h4>
                            Member:{" "}
                            <Link to={`/members/${review.user._id}`}>
                                {review.user.name}
                            </Link>
                        </h4>
                        <h4>Club review: {clubReview()}</h4>
                    </div>
                </div>
                <div className='row skinny mt-5'>
                    <div className='col'>
                        <Form>
                            <Segment vertical>
                                <h4>Booking process rating</h4>
                                <StarSelectDisplay
                                    score={review.bookingProcessStars}
                                />
                            </Segment>
                            <Segment vertical>
                                <h4>Booking process feedback</h4>
                                <TextArea
                                    disabled
                                    name='bookingProcessFeedback'
                                    placeholder='No text was provided by the member'
                                    value={review.bookingProcessFeedback}
                                />
                            </Segment>
                            {review.eventBooking && (
                                <>
                                    <Segment vertical>
                                        <h4>Event rating</h4>
                                        <StarSelectDisplay
                                            score={review.eventStars}
                                        />
                                    </Segment>
                                    <Segment vertical>
                                        <h4>Event feedback</h4>
                                        <TextArea
                                            disabled
                                            name='eventFeedback'
                                            placeholder='No text was provided by the member'
                                            value={review.eventFeedback}
                                        />
                                    </Segment>
                                </>
                            )}
                            <Segment vertical>{actions()}</Segment>
                        </Form>
                    </div>
                </div>
                <Modal open={showApproveModal}>
                    <Modal.Header>Approve review</Modal.Header>
                    <Modal.Content>
                        <p>
                            Are you sure you want to approve this review? £
                            {review.creditValue} of credit will be added to{" "}
                            {review.user.name}'s account.
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            primary
                            className='theme'
                            content='Approve'
                            onClick={() => approve()}
                        />
                        <Button
                            content='Cancel'
                            onClick={() => setShowApproveModal(false)}
                        />
                    </Modal.Actions>
                </Modal>
                <Modal open={showRejectModal}>
                    <Modal.Header>Reject review</Modal.Header>
                    <Modal.Content style={{ height: "300px" }}>
                        <p>For what reason are you rejecting this review?</p>
                        <Dropdown
                            selection
                            placeholder={"Status"}
                            options={rejectionReasons}
                            value={rejectionReason}
                            onChange={(_, { value }) =>
                                setRejectionReason(value)
                            }
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            primary
                            className='theme'
                            content='Reject'
                            onClick={() => reject()}
                        />
                        <Button
                            content='Cancel'
                            onClick={() => setShowRejectModal(false)}
                        />
                    </Modal.Actions>
                </Modal>
            </>
        );
    };

    return (
        <>
            <div className='d-flex main'>
                <Header history={history} />
                <div className='container'>{content()}</div>
            </div>
            <Footer />
        </>
    );
};

export default Show;
