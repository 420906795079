import React, { Component } from "react";
import {
    Input,
    Segment,
    Dropdown,
    Checkbox,
    TextArea,
    Label,
    Button,
    Select,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Member from "../../../models/Member";
import TeeTimeBooking from "../../../models/TeeTimeBooking";
import EventBooking from "../../../models/EventBooking";
import CourseVoucher from "../../../models/CourseVoucher";
import Role from "../../../models/Role";
import DataTable from "../../../assets/components/dataTable";
import Details from "../../../assets/components/user/Details";
import EmailAddresses from "../../../assets/components/user/EmailAddresses";
import PhoneNumbers from "../../../assets/components/user/PhoneNumbers";
import Addresses from "../../../assets/components/user/Addresses";
import Roles from "../../../assets/components/user/Roles";
import Entities from "../../../assets/components/user/Entities";
import Notes from "../../../assets/components/user/Notes";
import Memberships from "../../../assets/components/user/Memberships";
import Management from "../../../assets/components/user/Management";
import Transactions from "../../../assets/components/user/Transactions";
import InterestsSelect from "../../../assets/components/user/InterestsSelect";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import CenterLoader from "../../../assets/components/CenterLoader";
import moment from "moment";
import ImageCropper from "../../../assets/components/ImageCropper";
import { Link } from "react-router-dom";
import industryList from "../../../utilities/industryList";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingUser: true,
            loadingRoles: true,
            teeTimes: [],
            notes: [],
            eventBookings: [],
            transactions: [],
            vouchers: [],
            error: null,
            member: new Member(),
        };

        this.statusColours = {
            requested: "#007ab5",
            pending: "#007ab5",
            confirmed: "#00b571",
            booked: "#00b5ad",
            "awaiting confirmation": "#ffc53b",
            "cancellation requested": "#d76e6e",
            cancelled: "#7d2525",
        };
    }

    componentWillUnmount() {
        this.props.dispatch(ValidationActions.remove());
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.getUser(id);
        this.getRoles();
        this.getTeeTimes(id);
        this.getNotes(id);
        this.getEventBookings();
        this.getTransactions(id);
        this.getVouchers(id);
    }

    displayError() {
        if (this.state.error === 404) {
            return <Partial404 />;
        }

        return <Partial500 />;
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors),
            );
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className='has-error'>
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    getUser(id) {
        Member.show(id)
            .then((member) => {
                this.setState({ loadingUser: false, member });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingUser: false,
                    loadingRoles: false,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    getRoles() {
        Role.index()
            .then(({ data }) => {
                this.setState({
                    loadingRoles: false,
                    roles: data,
                });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification("error", "Failed to user, please try again");
            });
    }

    getTeeTimes(id) {
        TeeTimeBooking.userIndex(id, { limit: 100 })
            .then((data) => {
                this.setState({ teeTimes: data.data });
            })
            .catch((error) => {
                this.notification(
                    "error",
                    "Something went wrong, please try again",
                );
            });
    }

    getNotes(id) {
        Member.getNotes(id)
            .then((notes) => {
                this.setState({ notes });
            })
            .catch(() => {
                this.notification(
                    "error",
                    "Something went wrong, please try again",
                );
            });
    }

    getEventBookings() {
        const { id } = this.props.match.params;
        EventBooking.userIndex(id, { limit: 100 })
            .then((data) => {
                this.setState({ eventBookings: data.bookings });
            })
            .catch((error) => {
                this.notification(
                    "error",
                    "Something went wrong, please try again",
                );
            });
    }

    getTransactions(id) {
        Member.getUserTransactions(id)
            .then((transactions) => {
                this.setState({ transactions });
            })
            .catch(() => {
                this.notification(
                    "error",
                    "Something went wrong, please try again",
                );
            });
    }

    getVouchers(id) {
        CourseVoucher.userIndex(id)
            .then(({ data }) => {
                this.setState({ vouchers: data });
            })
            .catch(() => {
                this.notification(
                    "error",
                    "Something went wrong, please try again",
                );
            });
    }

    onChangeProfileImage(base64ImageString) {
        const { member } = this.state;
        member.updateProfileImage(base64ImageString).then((response) => {
            member.profileImage = response.data.data.profileImage;
            this.setState({ member });
        });
    }

    update(member) {
        this.setState({ member });
    }

    saveDetails() {
        this.setState({ saving: true });
        this.state.member
            .update()
            .then(() => {
                this.setState({ saving: false });
                this.props.dispatch(ValidationActions.remove());
                this.notification("success", "User saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save user");
                this.handleValidationErrors(error);
            });
    }

    saveRoles() {
        this.setState({ saving: true });
        this.state.member
            .updateRoles()
            .then(() => {
                this.setState({ saving: false });
                this.props.dispatch(ValidationActions.remove());
                this.notification("success", "User saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save user");
                this.handleValidationErrors(error);
            });
    }

    saveEntities() {
        this.setState({ saving: true });
        this.state.member
            .updateEntities()
            .then(() => {
                this.setState({ saving: false });
                this.props.dispatch(ValidationActions.remove());
                this.notification("success", "User saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save user");
                this.handleValidationErrors(error);
            });
    }

    async saveNote(note) {
        const newNote = await this.state.member.storeNote(note);
        const { notes } = this.state;
        notes.push(newNote);
        this.setState({ notes });
    }

    async saveTransaction(transaction) {
        const { id } = this.props.match.params;
        await this.state.member.storeCreditTransaction(transaction);
        this.getTransactions(id);
        this.getUser(id);
    }

    delete() {
        const confirm = window.confirm(
            "Are you sure you want to delete this user?",
        );
        if (confirm === true) {
            this.state.member
                .delete()
                .then(() => {
                    this.setState({ deleting: false });
                    this.notification("success", "User deleted");
                    this.props.history.push("/members");
                })
                .catch((error) => {
                    this.notification("error", "Failed to delete user");
                });
        }
    }

    unDelete() {
        const confirm = window.confirm(
            "Are you sure you want to un-delete this user?",
        );
        if (confirm === true) {
            this.state.member
                .unDelete()
                .then(() => {
                    const { id } = this.props.match.params;
                    this.setState({ deleting: false });
                    this.notification("success", "User un-deleted");
                    this.getUser(id);
                })
                .catch((error) => {
                    this.notification("error", "Failed to un-delete user");
                });
        }
    }

    removePaymentMethod() {
        const confirm = window.confirm(
            "Are you sure you want to remove the member's payment method?",
        );
        if (confirm === true) {
            this.state.member
                .removePaymentMethod()
                .then(() => {
                    this.notification("success", "Payment method removed");
                })
                .catch((error) => {
                    this.notification(
                        "error",
                        "Failed to remove payment method",
                    );
                });
        }
    }

    onChangeInput(property, value) {
        const { member } = this.state;
        member[property] = value;
        this.setState({ member });
    }

    loaded() {
        return (
            this.state.loadingUser === false &&
            this.state.loadingRoles === false
        );
    }

    sendWelcomeEmail() {
        const confirm = window.confirm(
            "Are you sure you want to send this user a welcome email?",
        );
        if (confirm === true) {
            this.setState({ saving: true });
            this.state.member
                .sendWelcomeEmail()
                .then((response) => {
                    this.setState({ saving: false });
                    this.notification(
                        "success",
                        "Welcome email sent successfully",
                    );
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    this.notification("error", "Failed to send welcome email");
                });
        }
    }

    onChangeBoolean(property) {
        const { member } = this.state;
        member[property] = !member[property];
        this.setState({ member });
    }

    sendPasswordResetEmail() {
        const confirm = window.confirm(
            "Are you sure you want to send this user a pasword reset email?",
        );
        if (confirm === true) {
            this.setState({ saving: true });
            this.state.member
                .sendPasswordResetEmail()
                .then((response) => {
                    this.setState({ saving: false });
                    this.notification(
                        "success",
                        "Password reset email sent successfully",
                    );
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    this.notification(
                        "error",
                        "Failed to send password reset email",
                    );
                });
        }
    }

    showActionsDropdown() {
        return (
            <>
                <Dropdown
                    text='Options'
                    icon='cog'
                    floating
                    labeled
                    button
                    className='icon'
                >
                    <Dropdown.Menu>
                        <Dropdown.Header icon='cog' content='Options' />
                        <Dropdown.Divider />
                        <Dropdown.Item
                            icon='handshake outline'
                            text='Send welcome email'
                            onClick={this.sendWelcomeEmail.bind(this)}
                        />
                        <Dropdown.Item
                            icon='shield'
                            text='Send password reset email'
                            onClick={this.sendPasswordResetEmail.bind(this)}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    userBasic() {
        if (this.loaded() === false) {
            return "";
        }

        return (
            <div className={styles.topSegment + " skinny"}>
                <div className='row skinny'>
                    <div className='col text-center'>
                        <img
                            className='profile-logo-lg'
                            src={this.state.member.profileImageUrl}
                        />
                        <ImageCropper
                            onChange={this.onChangeProfileImage.bind(this)}
                        />
                        <h2>{this.state.member.name}</h2>
                        <p>{this.state.member.email}</p>
                        {this.showActionsDropdown()}
                    </div>
                </div>
            </div>
        );
    }

    membershipDetailsTab() {
        return (
            <Tab eventKey='membership' title='Membership'>
                <div className={styles.mainSegment}>
                    <h2>Membership</h2>
                    <Memberships
                        member={this.state.member}
                        roles={this.state.roles}
                        saveRoles={this.saveRoles.bind(this)}
                        save={this.saveDetails.bind(this)}
                    />
                </div>
            </Tab>
        );
    }

    rolesTab() {
        return (
            <Tab eventKey='userRoles' title='Roles & permissions'>
                <div className={styles.mainSegment}>
                    <h3>Roles</h3>
                    <p>Roles apply groups of permissions to a user.</p>
                    {this.errorsFor("roles")}
                    <Roles
                        roles={this.state.roles.filter((role) =>
                            ["APP_USER", "ENTITY_ADMIN"].includes(role.key),
                        )}
                        save={this.saveRoles.bind(this)}
                        user={this.state.member}
                    />
                </div>
            </Tab>
        );
    }

    teeTimesTable(teeTimes) {
        const headers = [
            { key: "club", text: "Club", searchable: true },
            { key: "timeslot", text: "Time slot", searchable: true },
            { key: "date", text: "Booking date", searchable: true },
            { key: "requested", text: "Request date", searchable: true },
            { key: "status", text: "Status", searchable: true },
            { key: "payment", text: "Payment", searchable: true },
            { key: "claimed", text: "Claimed" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = teeTimes
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((teeTime) => {
                const row = {
                    club: {
                        value: (
                            <Link
                                to={`/golf-clubs/${teeTime.choices[0].course.club._id}`}
                            >
                                {teeTime.choices[0].course.club.name}
                            </Link>
                        ),
                        search: teeTime.choices[0].course.club.name,
                    },
                    timeslot: {
                        value: teeTime.confirmedBooking ? (
                            <b>{teeTime.confirmedBooking.time}</b>
                        ) : (
                            teeTime.timeslot
                        ),
                        search: teeTime.timeslot,
                    },
                    date: {
                        value: teeTime.confirmedBooking ? (
                            <b>
                                {moment(teeTime.confirmedBooking.date).format(
                                    "Do MMMM YYYY",
                                )}
                            </b>
                        ) : (
                            moment(teeTime.date).format("Do MMMM YYYY")
                        ),
                        search: moment(teeTime.date).unix(),
                    },
                    requested: {
                        value: moment(teeTime.createdAt).format(
                            "Do MMMM YYYY, HH:MM",
                        ),
                        search: moment(teeTime.createdAt).unix(),
                    },
                    status: {
                        value: this.styledStatus(teeTime.status),
                        search: teeTime.status,
                    },
                    payment: {
                        value: teeTime.paymentStatus,
                        search: teeTime.paymentStatus,
                    },
                    claimed: { value: this.showClaimedLabel(teeTime) },
                };

                if (this.props.user.has("MASHIE_ADMIN")) {
                    row.actions = { value: this.viewTeeTimeButton(teeTime) };
                }

                return row;
            });

        return <DataTable headers={headers} data={data} />;
    }

    eventBookingsTable(eventBookings) {
        const headers = [
            { key: "event", text: "Event" },
            { key: "venue", text: "Venue" },
            { key: "eventDate", text: "Event date" },
            { key: "requestDate", text: "Request date" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = eventBookings
            .sort((a, b) => (a.event.date < b.event.date ? 1 : -1))
            .map((booking) => {
                const row = {
                    event: {
                        value: booking.event ? (
                            <Link to={`/events/${booking.event._id}`}>
                                {booking.event.name}
                            </Link>
                        ) : (
                            "Event not found"
                        ),
                        search: booking.event?.name,
                    },
                    venue: {
                        value: booking.event?.club ? (
                            <Link to={`/golf-clubs/${booking.event.club._id}`}>
                                {booking.event.club.name}
                            </Link>
                        ) : (
                            ""
                        ),
                        search: booking.event?.club?.name,
                    },
                    eventDate: {
                        value: booking.event
                            ? moment(booking.event.date).format("Do MMMM YYYY")
                            : "-",
                    },
                    requestDate: {
                        value: moment(booking.createdAt).format(
                            "Do MMMM YYYY, HH:MM",
                        ),
                    },
                };

                if (this.props.user.has("MASHIE_ADMIN")) {
                    row.actions = {
                        value: this.viewEventBookingButton(booking),
                    };
                }

                return row;
            });

        return <DataTable headers={headers} data={data} />;
    }

    viewTeeTimeButton(teeTime) {
        return (
            <Link to={`/tee-time-requests/${teeTime._id}`}>
                <Button primary className='theme'>
                    View
                </Button>
            </Link>
        );
    }

    viewEventBookingButton(eventBooking) {
        return (
            <Link to={`/events/bookings/${eventBooking._id}`}>
                <Button primary className='theme'>
                    View
                </Button>
            </Link>
        );
    }

    styledStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                {status}
            </Label>
        );
    }

    showClaimedLabel(teeTime) {
        if (typeof teeTime.claimedBy === "undefined") {
            return "-";
        }

        return <Label>{teeTime.claimedBy.name}</Label>;
    }

    bookingsTab() {
        return (
            <Tab eventKey='bookings' title='Bookings'>
                <div className={styles.mainSegment}>
                    <h3>Bookings</h3>
                    <Tabs defaultActiveKey='teeTimes' id='user-bookings-tabs'>
                        <Tab eventKey='teeTimes' title='Tee Times'>
                            <div className={styles.mainSegment}>
                                <h3>Tee Times</h3>
                                {this.teeTimesTable(this.state.teeTimes)}
                            </div>
                        </Tab>
                        <Tab eventKey='eventBookings' title='Event Bookings'>
                            <div className={styles.mainSegment}>
                                <h3>Event Bookings</h3>
                                {this.eventBookingsTable(
                                    this.state.eventBookings,
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Tab>
        );
    }

    golfingInfoTab() {
        return (
            <Tab eventKey='golfingInfo' title='Golfing'>
                <div className={styles.mainSegment}>
                    <h2>Golfing information</h2>
                    {this.props.user.isMashieAdmin() && (
                        <Segment vertical>
                            <label className='with-input'>
                                Mashie handicap
                            </label>
                            <Input
                                fluid
                                name='MashieHandicap'
                                value={this.state.member.MashieHandicap}
                                onChange={({ target }) =>
                                    this.onChangeInput(
                                        "MashieHandicap",
                                        target.value,
                                    )
                                }
                            />
                        </Segment>
                    )}
                    <Segment vertical>
                        <label className='with-input'>WHS handicap</label>
                        <Input
                            fluid
                            name='CONGUHandicap'
                            value={this.state.member.CONGUHandicap}
                            onChange={({ target }) =>
                                this.onChangeInput(
                                    "CONGUHandicap",
                                    target.value,
                                )
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Home golf course</label>
                        <Input
                            fluid
                            name='homeGolfCourse'
                            value={this.state.member.homeGolfCourse}
                            onChange={({ target }) =>
                                this.onChangeInput(
                                    "homeGolfCourse",
                                    target.value,
                                )
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>
                            Secondary golf course
                        </label>
                        <Input
                            fluid
                            name='secondaryGolfCourse'
                            value={this.state.member.secondaryGolfCourse}
                            onChange={({ target }) =>
                                this.onChangeInput(
                                    "secondaryGolfCourse",
                                    target.value,
                                )
                            }
                        />
                    </Segment>
                    <Button
                        size='large'
                        floated='right'
                        className='theme mt-3'
                        primary
                        onClick={this.saveDetails.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </div>
            </Tab>
        );
    }

    clothingTab() {
        return (
            <Tab eventKey='clothingInfo' title='Clothing'>
                <div className={styles.mainSegment}>
                    <h2>Clothing information</h2>
                    <Segment vertical>
                        <label className='with-input'>Chest size</label>
                        <Input
                            fluid
                            name='chestSize'
                            value={this.state.member.chestSize}
                            onChange={({ target }) =>
                                this.onChangeInput("chestSize", target.value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Waist size</label>
                        <Input
                            fluid
                            name='waistSize'
                            value={this.state.member.waistSize}
                            onChange={({ target }) =>
                                this.onChangeInput("waistSize", target.value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Trouser length</label>
                        <Input
                            fluid
                            name='trouserLength'
                            value={this.state.member.trouserLength}
                            onChange={({ target }) =>
                                this.onChangeInput(
                                    "trouserLength",
                                    target.value,
                                )
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Collar size</label>
                        <Input
                            fluid
                            name='collarSize'
                            value={this.state.member.collarSize}
                            onChange={({ target }) =>
                                this.onChangeInput("collarSize", target.value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Shoe size</label>
                        <Input
                            fluid
                            name='shoeSize'
                            value={this.state.member.shoeSize}
                            onChange={({ target }) =>
                                this.onChangeInput("shoeSize", target.value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Preferred fit</label>
                        <Input
                            fluid
                            name='preferredFit'
                            value={this.state.member.preferredFit}
                            onChange={({ target }) =>
                                this.onChangeInput("preferredFit", target.value)
                            }
                        />
                    </Segment>
                    <Button
                        size='large'
                        floated='right'
                        className='theme mt-3'
                        primary
                        onClick={this.saveDetails.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </div>
            </Tab>
        );
    }

    transactionsTab() {
        if (this.props.user.isMashieAdmin()) {
            return (
                <Tab eventKey='transactionsInfo' title='Transactions'>
                    <div className={styles.mainSegment}>
                        <div className='row'>
                            <div className='col'>
                                <label className='with-input'>
                                    Event credit balance
                                </label>
                                <h2 className='mt-0'>
                                    £ {this.state.member.eventCreditBalance}
                                </h2>
                            </div>
                            <div className='col'>
                                <label className='with-input'>
                                    Teetime credit balance
                                </label>
                                <h2 className='mt-0'>
                                    £ {this.state.member.teeTimeCreditBalance}
                                </h2>
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='col'>
                                <h2>Transactions</h2>
                                <Transactions
                                    user={this.state.member}
                                    transactions={this.state.transactions}
                                    vouchers={this.state.vouchers}
                                    save={this.saveTransaction.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </Tab>
            );
        }
    }

    notesTab() {
        if (this.props.user.isMashieAdmin()) {
            return (
                <Tab eventKey='notes' title='Notes'>
                    <div className={styles.mainSegment}>
                        <h2>Notes</h2>
                        <Notes
                            user={this.state.member}
                            notes={this.state.notes}
                            save={this.saveNote.bind(this)}
                        />
                    </div>
                </Tab>
            );
        }
    }

    otherTab() {
        return (
            <Tab eventKey='otherInfo' title='Other'>
                <div className={styles.mainSegment}>
                    <h2>Other information</h2>
                    {this.props.user.isMashieAdmin() && (
                        <Segment vertical>
                            <label className='with-input'>Introducer</label>
                            <Input
                                fluid
                                name='introducer'
                                value={this.state.member.introducer || ""}
                                onChange={({ target }) =>
                                    this.onChangeInput(
                                        "introducer",
                                        target.value,
                                    )
                                }
                            />
                        </Segment>
                    )}
                    <Segment vertical>
                        <label className='with-input'>Corporate level</label>
                        <Select
                            options={[
                                { key: 0, text: "Director", value: "director" },
                                { key: 1, text: "Officer", value: "officer" },
                                { key: 2, text: "Manager", value: "manager" },
                                { key: 3, text: "Employee", value: "employee" },
                            ]}
                            value={this.state.member.corporateLevel}
                            onChange={(_, { value }) =>
                                this.onChangeInput("corporateLevel", value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Industry type</label>
                        <Select
                            options={industryList.map((item) => {
                                return {
                                    key: item.key,
                                    text: item.text,
                                    value: item.value,
                                };
                            })}
                            value={this.state.member.industryType}
                            onChange={(_, { value }) =>
                                this.onChangeInput("industryType", value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Interests</label>
                        <InterestsSelect
                            value={this.state.member.interests}
                            onChange={(value) =>
                                this.onChangeInput("interests", value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <div className='ui form'>
                            <label className='with-input'>Personal bio</label>
                            <TextArea
                                rows='10'
                                name='personalBio'
                                value={this.state.member.personalBio || ""}
                                onChange={({ target }) =>
                                    this.onChangeInput(
                                        "personalBio",
                                        target.value,
                                    )
                                }
                                placeholder='Personal bio...'
                            />
                        </div>
                    </Segment>
                    <Segment vertical>
                        <div className='ui form'>
                            <label className='with-input'>Business bio</label>
                            <TextArea
                                rows='10'
                                name='businessBio'
                                value={this.state.member.businessBio || ""}
                                onChange={({ target }) =>
                                    this.onChangeInput(
                                        "businessBio",
                                        target.value,
                                    )
                                }
                                placeholder='Business bio...'
                            />
                        </div>
                    </Segment>
                    {this.props.user.isMashieAdmin() && (
                        <>
                            <Segment vertical>
                                <label className='with-input'>
                                    Overriding Clothing Discount Code
                                </label>
                                <Input
                                    fluid
                                    name='discountCode'
                                    value={this.state.member.discountCode || ""}
                                    onChange={({ target }) =>
                                        this.onChangeInput(
                                            "discountCode",
                                            target.value,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                <label className='with-input'>
                                    Can Request Credit
                                </label>
                                <Checkbox
                                    toggle
                                    name='primary'
                                    label={
                                        this.state.member.canRequestCredit
                                            ? "On"
                                            : "Off"
                                    }
                                    checked={this.state.member.canRequestCredit}
                                    onChange={() =>
                                        this.onChangeBoolean("canRequestCredit")
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                <label className='with-input'>
                                    Invoice only
                                </label>
                                <Checkbox
                                    toggle
                                    name='primary'
                                    label={
                                        this.state.member.invoiceOnly
                                            ? "On"
                                            : "Off"
                                    }
                                    checked={this.state.member.invoiceOnly}
                                    onChange={() =>
                                        this.onChangeBoolean("invoiceOnly")
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                <label className='with-input'>
                                    Unsubscribe from service communications
                                </label>
                                <p>
                                    <small>
                                        The member will no longer receive
                                        notifications from Mashie about updates
                                        to their bookings and they will need to
                                        check for updates about them manually.
                                    </small>
                                </p>
                                <Checkbox
                                    toggle
                                    name='primary'
                                    label={
                                        this.state.member
                                            .unsubscribeFromServiceCommunications
                                            ? "On"
                                            : "Off"
                                    }
                                    checked={
                                        this.state.member
                                            .unsubscribeFromServiceCommunications
                                    }
                                    onChange={(_, { checked }) =>
                                        this.onChangeInput(
                                            "unsubscribeFromServiceCommunications",
                                            checked,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                <label className='with-input'>
                                    Unsubscribe from first party communications
                                </label>
                                <p>
                                    <small>
                                        The member will no longer receive
                                        communications from Mashie in relation
                                        to our events, international trips,
                                        series and other general updates.
                                    </small>
                                </p>
                                <Checkbox
                                    toggle
                                    name='primary'
                                    label={
                                        this.state.member
                                            .unsubscribeFromFirstPartyCommunications
                                            ? "On"
                                            : "Off"
                                    }
                                    checked={
                                        this.state.member
                                            .unsubscribeFromFirstPartyCommunications
                                    }
                                    onChange={(_, { checked }) =>
                                        this.onChangeInput(
                                            "unsubscribeFromFirstPartyCommunications",
                                            checked,
                                        )
                                    }
                                />
                            </Segment>
                            <Segment vertical>
                                <label className='with-input'>
                                    Unsubscribe from third party communications
                                </label>
                                <p>
                                    <small>
                                        The member will no longer receive
                                        communications from Mashie in relation
                                        to our partner brands.
                                    </small>
                                </p>
                                <Checkbox
                                    toggle
                                    name='primary'
                                    label={
                                        this.state.member
                                            .unsubscribeFromThirdPartyCommunications
                                            ? "On"
                                            : "Off"
                                    }
                                    checked={
                                        this.state.member
                                            .unsubscribeFromThirdPartyCommunications
                                    }
                                    onChange={(_, { checked }) =>
                                        this.onChangeInput(
                                            "unsubscribeFromThirdPartyCommunications",
                                            checked,
                                        )
                                    }
                                />
                            </Segment>
                        </>
                    )}
                    <Button
                        size='large'
                        floated='right'
                        className='theme mt-3'
                        primary
                        onClick={this.saveDetails.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </div>
            </Tab>
        );
    }

    managementTab() {
        return (
            <Tab eventKey='management' title='Manage'>
                <div className={styles.mainSegment}>
                    <h2>Manage</h2>
                    <Management
                        user={this.state.member}
                        onDelete={this.delete.bind(this)}
                        onUnDelete={this.unDelete.bind(this)}
                        onRemovePaymentMethod={this.removePaymentMethod.bind(
                            this,
                        )}
                    />
                </div>
            </Tab>
        );
    }

    detailsTab() {
        return (
            <Tab eventKey='profile' title='Profile'>
                <div className={styles.mainSegment}>
                    <h2>Profile</h2>
                    <Details
                        user={this.state.member}
                        update={this.update.bind(this)}
                    />
                    <EmailAddresses
                        user={this.state.member}
                        update={this.update.bind(this)}
                    />
                    <PhoneNumbers
                        user={this.state.member}
                        update={this.update.bind(this)}
                    />
                    <Addresses
                        user={this.state.member}
                        update={this.update.bind(this)}
                    />
                    <Segment vertical>
                        <Button
                            size='large'
                            floated='right'
                            className='theme'
                            primary
                            onClick={this.saveDetails.bind(this)}
                            disabled={this.state.saving}
                            loading={this.state.saving}
                        >
                            Save
                        </Button>
                    </Segment>
                </div>
            </Tab>
        );
    }

    userEntitiesTab() {
        if (this.props.user.isMashieAdmin()) {
            return (
                <Tab eventKey='entities' title='Entities'>
                    <div className={styles.mainSegment}>
                        <h2>Entities</h2>
                        <Entities
                            user={this.state.member}
                            update={this.update.bind(this)}
                            save={this.saveEntities.bind(this)}
                        />
                    </div>
                </Tab>
            );
        }
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className='container'>
                {this.userBasic()}
                <div className='row skinny'>
                    <div className='col'>
                        <Tabs
                            defaultActiveKey='profile'
                            id='uncontrolled-tab-example'
                        >
                            {this.detailsTab()}
                            {this.rolesTab()}
                            {this.userEntitiesTab()}
                            {this.golfingInfoTab()}
                            {this.clothingTab()}
                            {this.membershipDetailsTab()}
                            {this.notesTab()}
                            {this.bookingsTab()}
                            {this.transactionsTab()}
                            {this.otherTab()}
                            {this.managementTab()}
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className='d-flex main'>
                    <Header history={this.props.history} />

                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
