import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import { Button, Dropdown } from "semantic-ui-react";
import PartnerClubContract from "../../models/PartnerClubContract";
import { format, subYears } from "date-fns";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import DatePicker from "../../assets/components/DatePicker";

const Invoices = ({ user, history }) => {
    if (user.has("MASHIE_ADMIN") === false) {
        history.push("/members");
    }

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [summaryData, setSummaryData] = useState({ paid: 0, unpaid: 0 });
    const [total, setTotal] = useState(0);
    const [table, setTable] = useState({
        page: 1,
        term: "",
        sort: "createdAt",
        order: "desc",
        limit: 25,
    });
    const [filters, setFilters] = useState({
        course: null,
        paid: null,
        type: null,
        createdAfter: subYears(new Date(), 1),
        createdBefore: new Date(),
        paidAfter: subYears(new Date(), 1),
        paidBefore: new Date(),
    });

    const onTableChange = (params) => {
        setTable({ ...params });
    };

    useEffect(() => {
        const fetchData = async () => {
            const params = Object.assign({}, table, computeFilters(filters));
            const { data, total } = await PartnerClubContract.invoices(params);
            const summary = await PartnerClubContract.invoicesSummary(params);
            setSummaryData(summary);
            setData(data);
            setLoading(false);
            setTotal(total);
        };
        fetchData();
    }, [table, filters]);

    const onChangeFilter = (property, value) => {
        setTable({ ...table, page: 1 });
        setFilters({ ...filters, [property]: value });
    };

    const computeFilters = (filters) => {
        const toSend = {};
        for (const filter in filters) {
            // We only want to send the paidAfter and paidBefore filters if they are explicitely filtering for only paid invoices
            if (
                filters.paid !== true &&
                (filter === "paidBefore" || filter === "paidAfter")
            ) {
                continue;
            }
            toSend[filter] = filters[filter];
        }

        return toSend;
    };

    const headers = [
        { key: "invoice", text: "Invoice" },
        { key: "status", text: "Status" },
        { key: "createdAt", text: "Date created" },
        { key: "paidAt", text: "Date paid" },
        { key: "price", text: "Price" },
        { key: "course", text: "Course" },
        { key: "contractType", text: "Type" },
        { key: "actions", text: "Actions" },
    ];

    return (
        <>
            <div className='d-flex main'>
                <Header history={history} />
                <div className='container-fluid'>
                    <div className='row page-header mt-3'>
                        <div className='col-sm-12 col-md-10'>
                            <h2>Partner club contracts</h2>
                        </div>
                        <div className='col-sm-12 col-md-2 text-right'>
                            <Link to='/partner-club-contracts/create'>
                                <Button primary className='theme'>
                                    Create contract
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <h3>
                                <span className='mr-3'>
                                    Profit: £{summaryData.paid}
                                </span>
                                <span>Outstanding: £{summaryData.unpaid} </span>
                            </h3>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <h3>Filters</h3>
                            <GolfClubSearchSelect
                                placeholder='Filter by club'
                                value={filters.club}
                                onChange={(value) =>
                                    onChangeFilter("club", value)
                                }
                            />
                            <Dropdown
                                selection
                                placeholder={"Status"}
                                options={[
                                    { key: 0, value: null, text: "All" },
                                    {
                                        key: 1,
                                        value: true,
                                        text: "Paid",
                                    },
                                    {
                                        key: 2,
                                        value: false,
                                        text: "Unpaid",
                                    },
                                ]}
                                value={filters.paid}
                                onChange={(_, { value }) =>
                                    onChangeFilter("paid", value)
                                }
                                className={"ml-3"}
                            />
                            <Dropdown
                                selection
                                placeholder={"Type"}
                                options={[
                                    { key: 0, value: null, text: "All" },
                                    {
                                        key: 1,
                                        value: "PCP",
                                        text: "PCP",
                                    },
                                    {
                                        key: 2,
                                        value: "CORPORATE",
                                        text: "Corporate",
                                    },
                                ]}
                                value={filters.status}
                                onChange={(_, { value }) =>
                                    onChangeFilter("type", value)
                                }
                                className={"ml-3"}
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <DatePicker
                                value={filters.createdAfter ?? new Date()}
                                onDayChange={(value) => {
                                    onChangeFilter("createdAfter", value);
                                }}
                                label='Created after'
                            />
                            <DatePicker
                                className={"ml-3"}
                                value={filters.createdBefore ?? new Date()}
                                onDayChange={(value) => {
                                    onChangeFilter("createdBefore", value);
                                }}
                                label='Created before'
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <DatePicker
                                disabled={filters.paid !== true}
                                value={filters.paidAfter ?? new Date()}
                                onDayChange={(value) => {
                                    onChangeFilter("paidAfter", value);
                                }}
                                label='Paid after'
                            />
                            <DatePicker
                                disabled={filters.paid !== true}
                                className={"ml-3"}
                                value={filters.paidBefore ?? new Date()}
                                onDayChange={(value) => {
                                    onChangeFilter("paidBefore", value);
                                }}
                                label='Paid before'
                            />
                            {filters.paid !== true && (
                                <span className='ml-3'>
                                    <strong>
                                        To enable these filters, set the
                                        "Status" filter to "Paid"
                                    </strong>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col'>
                            <DataTable
                                loading={loading}
                                headers={headers}
                                data={data.map((item) => {
                                    return {
                                        invoice: {
                                            value: item.invoice,
                                        },
                                        status: {
                                            value: item.paidAt
                                                ? "Paid"
                                                : "Unpaid",
                                        },
                                        createdAt: {
                                            value: format(
                                                new Date(item.createdAt),
                                                "dd/MM/yyyy",
                                            ),
                                        },
                                        paidAt: {
                                            value: item.paidAt
                                                ? format(
                                                      new Date(item.paidAt),
                                                      "dd/MM/yyyy",
                                                  )
                                                : "-",
                                        },
                                        price: {
                                            value: `£${item.price}`,
                                        },
                                        course: {
                                            value: (
                                                <Link
                                                    to={`/golf-clubs/${item.club._id}`}
                                                >
                                                    {item.course.name} at{" "}
                                                    {item.club.name}
                                                </Link>
                                            ),
                                        },
                                        contractType: {
                                            value: item.contract.allocated
                                                ? "PCP"
                                                : "CORPORATE",
                                        },
                                        actions: {
                                            value: (
                                                <>
                                                    <Link
                                                        to={`/partner-club-contracts/${item.contract._id}`}
                                                    >
                                                        <Button
                                                            primary
                                                            className='theme'
                                                        >
                                                            View contract
                                                        </Button>
                                                    </Link>
                                                </>
                                            ),
                                        },
                                    };
                                })}
                                totalRecords={total}
                                page={table.page}
                                onChange={onTableChange}
                                searchTerm={table.term}
                                sort={table.sort}
                                order={table.order}
                                limit={table.limit}
                                sortOptions={[
                                    { text: "Date created", key: "createdAt" },
                                    { text: "Date paid", key: "paidAt" },
                                    { text: "Invoice", key: "invoice" },
                                    { text: "Price", key: "price" },
                                ]}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Invoices);
