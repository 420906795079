import React, { useState } from "react";
import DataTable from "../dataTable";
import { Button, Modal, Input, Segment } from "semantic-ui-react";
import { format } from "date-fns";
import NumberInput from "../NumberInput";

const Invoices = ({ contract, refresh }) => {
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [newInvoiceInvoice, setNewInvoiceInvoice] = useState("");
    const [newInvoicePrice, setNewInvoicePrice] = useState(0);

    const storeInvoice = async () => {
        await contract.storeInvoice({
            invoice: newInvoiceInvoice,
            price: newInvoicePrice,
        });
        refresh();
        setOpenCreateModal(false);
        setNewInvoicePrice(0);
        setNewInvoiceInvoice("");
    };

    const markAsPaid = async (id) => {
        const confirm = window.confirm(
            "Are you sure you want to mark this invoice as paid?",
        );
        if (confirm === true) {
            await contract.markAsPaid(id);
            refresh();
        }
    };

    const deleteInvoice = async (id) => {
        const confirm = window.confirm(
            "Are you sure you want to delete this invoice?",
        );
        if (confirm === true) {
            await contract.deleteInvoice(id);
            refresh();
        }
    };

    const headers = [
        { key: "invoice", text: "Invoice" },
        { key: "price", text: "Price" },
        { key: "createdAt", text: "Created" },
        { key: "paidAt", text: "Paid" },
        { key: "actions", text: "Actions" },
    ];

    return (
        <>
            <div className='row mt-3'>
                <div className='col'>
                    <h3>
                        Profit: £{contract.profit} &nbsp;&nbsp; Outstanding: £
                        {contract.outstanding}
                    </h3>
                    <Button
                        primary
                        className='theme'
                        onClick={() => setOpenCreateModal(true)}
                    >
                        Create invoice
                    </Button>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col'>
                    <DataTable
                        headers={headers}
                        data={contract.invoices.map((invoice) => {
                            return {
                                invoice: {
                                    value: invoice.invoice,
                                },
                                price: {
                                    value: `£${invoice.price}`,
                                },
                                createdAt: {
                                    value: format(
                                        new Date(invoice.createdAt),
                                        "dd/MM/yyyy",
                                    ),
                                },
                                paidAt: {
                                    value: invoice.paidAt
                                        ? format(
                                              new Date(invoice.paidAt),
                                              "dd/MM/yyyy",
                                          )
                                        : "-",
                                },
                                actions: {
                                    value: (
                                        <>
                                            {invoice.paidAt === null && (
                                                <Button
                                                    primary
                                                    className='theme'
                                                    onClick={() =>
                                                        markAsPaid(invoice._id)
                                                    }
                                                >
                                                    Mark as paid
                                                </Button>
                                            )}{" "}
                                            <Button
                                                primary
                                                className='red'
                                                onClick={() =>
                                                    deleteInvoice(invoice._id)
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </>
                                    ),
                                },
                            };
                        })}
                        totalRecords={contract.invoices.length}
                        hideSearch
                    />
                </div>
            </div>
            <Modal size='mini' open={openCreateModal}>
                <Modal.Header>Create a new invoice</Modal.Header>
                <Modal.Content>
                    <Segment vertical>
                        <label className='with-input'>Invoice</label>
                        <Input
                            fluid
                            value={newInvoiceInvoice}
                            onChange={({ target }) =>
                                setNewInvoiceInvoice(target.value)
                            }
                        />
                    </Segment>
                    <Segment vertical>
                        <label className='with-input'>Price</label>
                        <NumberInput
                            fluid
                            decimal
                            value={newInvoicePrice}
                            onChange={setNewInvoicePrice}
                        />
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Cancel'
                        onClick={() => setOpenCreateModal(false)}
                    />
                    <Button
                        primary
                        className='theme'
                        content='Confirm'
                        onClick={() => storeInvoice()}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default Invoices;
