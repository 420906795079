import React, { useState, useEffect } from "react";
import useDispatchUtilities from "../../../utilities/useDispatchUtilities";
import { Segment, Checkbox } from "semantic-ui-react";
import CourseGroup from "../../../models/CourseGroup";

const CourseCreditAvailability = ({ dispatch, course, onChange }) => {
    const { setNotification } = useDispatchUtilities(dispatch);
    const [group, setGroup] = useState(null);
    const days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
    ];

    useEffect(() => {
        const getGroupInfo = async () => {
            try {
                const group = await CourseGroup.show(course.group);
                setGroup(group);
            } catch (_) {
                setNotification("error", "Failed to load course group");
            }
        };

        getGroupInfo();
    }, [course.group]);

    const onToggle = (day, time) => {
        const value = course.creditAvailability[day][time] === false;
        course.creditAvailability[day][time] = value;
        const oppositeTime = time === "am" ? "pm" : "am";
        const oppositeValue = course.creditAvailability[day][oppositeTime];
        if (value === false && oppositeValue === false) {
            course.creditAvailability[day][oppositeTime] = true;
        }
        onChange(course);
    };

    const renderDay = (day) => {
        if (group?.availability[day] <= 0) {
            return null;
        }

        return (
            <Segment vertical>
                <h3>
                    {day.charAt(0).toUpperCase()}
                    {day.substring(1)}
                </h3>
                <Checkbox
                    toggle
                    label='A.M'
                    checked={course.creditAvailability[day].am}
                    onChange={() => onToggle(day, "am")}
                />
                <Checkbox
                    className='ml-5'
                    toggle
                    label='P.M'
                    checked={course.creditAvailability[day].pm}
                    onChange={() => onToggle(day, "pm")}
                />
            </Segment>
        );
    };

    return days.map((day) => renderDay(day));
};

export default CourseCreditAvailability;
