import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./routes/auth/login";
import Register from "./routes/auth/register";
import Verify from "./routes/auth/verify";
import RequestPasswordReset from "./routes/auth/requestPasswordReset";
import ResetPassword from "./routes/auth/resetPassword";
import EntitiesIndex from "./routes/entities";
import EntitiesShow from "./routes/entities/show";
import EntitiesCreate from "./routes/entities/create";
import Settings from "./routes/settings";
import Reports from "./routes/reports";
import Terms from "./routes/other/Terms";
import Privacy from "./routes/other/Privacy";
import Search from "./routes/search";
import MashieAdminsIndex from "./routes/users/mashieAdmins/index";
import MashieAdminCreate from "./routes/users/mashieAdmins/create";
import MashieAdminShow from "./routes/users/mashieAdmins/show";
import MembersIndex from "./routes/users/members/index";
import MemberCreate from "./routes/users/members/create";
import MemberShow from "./routes/users/members/show";
import AdminsIndex from "./routes/users/admins/index";
import AdminCreate from "./routes/users/admins/create";
import AdminShow from "./routes/users/admins/show";
import GolfClubsIndex from "./routes/golfClubs/index";
import GolfClubsCreate from "./routes/golfClubs/create";
import GolfClubsShow from "./routes/golfClubs/show";
import EventsIndex from "./routes/events";
import EventsBookings from "./routes/events/bookings";
import EventsReserves from "./routes/events/reserves";
import EventsShow from "./routes/events/show";
import EventsBookingShow from "./routes/events/bookingsShow";
import EventsCreate from "./routes/events/create";
import EventsSeries from "./routes/events/series";
import EventsSeriesCreate from "./routes/events/seriesCreate";
import EventsSeriesShow from "./routes/events/seriesShow";
import OffersIndex from "./routes/offers";
import OffersShow from "./routes/offers/show";
import OffersCreate from "./routes/offers/create";
import CommunicationsIndex from "./routes/communications";
import CommunicationsShow from "./routes/communications/show";
import CommunicationsCreate from "./routes/communications/create";
import Error404 from "./routes/errors/404";
import TeeTimesIndex from "./routes/teeTimes/index";
import TeeTimesCreate from "./routes/teeTimes/create";
import TeeTimesShow from "./routes/teeTimes/show";
import TransactionsIndex from "./routes/transactions/index";
import PurchaseTransactionsIndex from "./routes/transactions/purchases";
import CourseGroupsIndex from "./routes/courseGroups/index";
import CourseGroupsCreate from "./routes/courseGroups/create";
import CourseGroupsShow from "./routes/courseGroups/show";

import { Provider } from "react-redux";
import store from "./store/init";
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import "./assets/scss/layout.scss";
import "react-day-picker/lib/style.css";
import AuthenticationCheck from "./utilities/AuthenticationCheck";
import RedirectIfAuthenticated from "./utilities/RedirectIfAuthenticated";
import apiHandler from "./utilities/ApiHandler";
import AppVariablesIndex from "./routes/appVariables";
import AppVariablesCreate from "./routes/appVariables/create";
import AppVariablesShow from "./routes/appVariables/show";
import CurrenciesIndex from "./routes/currencies";
import CurrenciesCreate from "./routes/currencies/create";
import CurrenciesShow from "./routes/currencies/show";
import PartnerClubContractIndex from "./routes/partnerClubContracts/index";
import PartnerClubContractInvoices from "./routes/partnerClubContracts/invoices";
import PartnerClubContractCreate from "./routes/partnerClubContracts/create";
import PartnerClubContractShow from "./routes/partnerClubContracts/show";
import CourseVoucherIndex from "./routes/courseVouchers/index";
import CourseVoucherCreate from "./routes/courseVouchers/create";
import CourseVoucherShow from "./routes/courseVouchers/show";
import ReviewsClubsIndex from "./routes/reviews/clubs/index";
import ReviewsClubsShow from "./routes/reviews/clubs/show";
import ReviewsBookingsIndex from "./routes/reviews/bookings/index";
import ReviewsBookingsShow from "./routes/reviews/bookings/show";
import PublicReviewQuestionnaire from "./routes/public/reviews/questionnaire";
import CreditAllocationsIndex from "./routes/creditAllocations/index";
import Notification from "./assets/components/Notification";

import axios from "axios";
import EventIndex from "./routes/public/events";
import EventShow from "./routes/public/events/show";
axios.defaults.withCredentials = true;

class App extends Component {
    render() {
        apiHandler(store);
        return (
            <Provider store={store}>
                <div className='App'>
                    <Notification />
                    <BrowserRouter>
                        <Switch>
                            (Authentication)
                            <Route
                                exact
                                path='/auth/login'
                                component={RedirectIfAuthenticated(Login)}
                            />
                            <Route
                                exact
                                path='/auth/register/:token'
                                component={RedirectIfAuthenticated(Register)}
                            />
                            <Route
                                exact
                                path='/auth/verify/:token'
                                component={RedirectIfAuthenticated(Verify)}
                            />
                            <Route
                                exact
                                path='/auth/reset-password'
                                component={RedirectIfAuthenticated(
                                    RequestPasswordReset,
                                )}
                            />
                            <Route
                                exact
                                path='/auth/reset-password/:token'
                                component={RedirectIfAuthenticated(
                                    ResetPassword,
                                )}
                            />
                            (Terms, PP, etc.)
                            <Route
                                exact
                                path='/terms-and-conditions/jun-2020'
                                component={Terms}
                            />
                            <Route
                                exact
                                path='/privacy-policy/jun-2020'
                                component={Privacy}
                            />
                            (Review questionnaires)
                            <Route
                                exact
                                path='/public/reviews/:reference'
                                component={PublicReviewQuestionnaire}
                            />
                            (Event Lists)
                            <Route
                                exact
                                path='/public/events'
                                component={EventIndex}
                            />
                            <Route
                                exact
                                path='/public/events/:event'
                                component={EventShow}
                            />
                            (Search)
                            <Route
                                exact
                                path='/search/:term'
                                component={AuthenticationCheck(Search, true)}
                            />
                            (Profile)
                            <Route
                                exact
                                path='/settings'
                                component={AuthenticationCheck(Settings)}
                            />
                            (Members)
                            <Route
                                exact
                                path='/members'
                                component={AuthenticationCheck(MembersIndex)}
                            />
                            <Route
                                exact
                                path='/members/create'
                                component={AuthenticationCheck(MemberCreate)}
                            />
                            <Route
                                exact
                                path='/members/:id'
                                component={AuthenticationCheck(MemberShow)}
                            />
                            (Admins)
                            <Route
                                exact
                                path='/admins'
                                component={AuthenticationCheck(AdminsIndex)}
                            />
                            <Route
                                exact
                                path='/admins/create'
                                component={AuthenticationCheck(AdminCreate)}
                            />
                            <Route
                                exact
                                path='/admins/:id'
                                component={AuthenticationCheck(AdminShow)}
                            />
                            (Mashie admin pages) (Users)
                            <Route
                                exact
                                path='/mashie-admins'
                                component={AuthenticationCheck(
                                    MashieAdminsIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/mashie-admins/create'
                                component={AuthenticationCheck(
                                    MashieAdminCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/mashie-admins/:id'
                                component={AuthenticationCheck(MashieAdminShow)}
                            />
                            (Entities)
                            <Route
                                exact
                                path='/entities'
                                component={AuthenticationCheck(EntitiesIndex)}
                            />
                            <Route
                                exact
                                path='/entities/create'
                                component={AuthenticationCheck(EntitiesCreate)}
                            />
                            <Route
                                exact
                                path='/entities/:id'
                                component={AuthenticationCheck(EntitiesShow)}
                            />
                            (Golf Clubs)
                            <Route
                                exact
                                path='/golf-clubs'
                                component={AuthenticationCheck(GolfClubsIndex)}
                            />
                            <Route
                                exact
                                path='/golf-clubs/create'
                                component={AuthenticationCheck(GolfClubsCreate)}
                            />
                            <Route
                                exact
                                path='/golf-clubs/:id'
                                component={AuthenticationCheck(GolfClubsShow)}
                            />
                            (Offers)
                            <Route
                                exact
                                path='/offers'
                                component={AuthenticationCheck(OffersIndex)}
                            />
                            <Route
                                exact
                                path='/offers/create'
                                component={AuthenticationCheck(OffersCreate)}
                            />
                            <Route
                                exact
                                path='/offers/:id'
                                component={AuthenticationCheck(OffersShow)}
                            />
                            (Communications)
                            <Route
                                exact
                                path='/communications'
                                component={AuthenticationCheck(
                                    CommunicationsIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/communications/create'
                                component={AuthenticationCheck(
                                    CommunicationsCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/communications/:id'
                                component={AuthenticationCheck(
                                    CommunicationsShow,
                                )}
                            />
                            (Events)
                            <Route
                                exact
                                path='/events'
                                component={AuthenticationCheck(EventsIndex)}
                            />
                            <Route
                                exact
                                path='/events/create'
                                component={AuthenticationCheck(EventsCreate)}
                            />
                            <Route
                                exact
                                path='/events/bookings'
                                component={AuthenticationCheck(EventsBookings)}
                            />
                            <Route
                                exact
                                path='/events/reserves'
                                component={AuthenticationCheck(EventsReserves)}
                            />
                            <Route
                                exact
                                path='/events/series'
                                component={AuthenticationCheck(EventsSeries)}
                            />
                            <Route
                                exact
                                path='/events/series/create'
                                component={AuthenticationCheck(
                                    EventsSeriesCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/events/series/:id'
                                component={AuthenticationCheck(
                                    EventsSeriesShow,
                                )}
                            />
                            <Route
                                exact
                                path='/events/:id'
                                component={AuthenticationCheck(EventsShow)}
                            />
                            <Route
                                exact
                                path='/events/bookings/:id'
                                component={AuthenticationCheck(
                                    EventsBookingShow,
                                )}
                            />
                            (Tee Time Requests)
                            <Route
                                exact
                                path='/tee-time-requests'
                                component={AuthenticationCheck(TeeTimesIndex)}
                            />
                            <Route
                                exact
                                path='/tee-time-requests/create'
                                component={AuthenticationCheck(TeeTimesCreate)}
                            />
                            <Route
                                exact
                                path='/tee-time-requests/:id'
                                component={AuthenticationCheck(TeeTimesShow)}
                            />
                            (Transactions)
                            <Route
                                exact
                                path='/transactions'
                                component={AuthenticationCheck(
                                    TransactionsIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/purchase-transactions'
                                component={AuthenticationCheck(
                                    PurchaseTransactionsIndex,
                                )}
                            />
                            (Contracts)
                            <Route
                                exact
                                path='/partner-club-contracts'
                                component={AuthenticationCheck(
                                    PartnerClubContractIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/partner-club-contracts/invoices'
                                component={AuthenticationCheck(
                                    PartnerClubContractInvoices,
                                )}
                            />
                            <Route
                                exact
                                path='/partner-club-contracts/create'
                                component={AuthenticationCheck(
                                    PartnerClubContractCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/partner-club-contracts/:id'
                                component={AuthenticationCheck(
                                    PartnerClubContractShow,
                                )}
                            />
                            (Vouchers)
                            <Route
                                exact
                                path='/course-vouchers'
                                component={AuthenticationCheck(
                                    CourseVoucherIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/course-vouchers/create'
                                component={AuthenticationCheck(
                                    CourseVoucherCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/course-vouchers/:id'
                                component={AuthenticationCheck(
                                    CourseVoucherShow,
                                )}
                            />
                            (Reviews)
                            <Route
                                exact
                                path='/reviews/clubs'
                                component={AuthenticationCheck(
                                    ReviewsClubsIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/reviews/clubs/:id'
                                component={AuthenticationCheck(
                                    ReviewsClubsShow,
                                )}
                            />
                            <Route
                                exact
                                path='/reviews/bookings'
                                component={AuthenticationCheck(
                                    ReviewsBookingsIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/reviews/bookings/:id'
                                component={AuthenticationCheck(
                                    ReviewsBookingsShow,
                                )}
                            />
                            (Credit allocations)
                            <Route
                                exact
                                path='/credit-allocations'
                                component={AuthenticationCheck(
                                    CreditAllocationsIndex,
                                )}
                            />
                            (Course groups)
                            <Route
                                exact
                                path='/course-groups'
                                component={AuthenticationCheck(
                                    CourseGroupsIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/course-groups/create'
                                component={AuthenticationCheck(
                                    CourseGroupsCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/course-groups/:id'
                                component={AuthenticationCheck(
                                    CourseGroupsShow,
                                )}
                            />
                            (App Variables)
                            <Route
                                exact
                                path='/app-variables'
                                component={AuthenticationCheck(
                                    AppVariablesIndex,
                                )}
                            />
                            <Route
                                exact
                                path='/app-variables/create'
                                component={AuthenticationCheck(
                                    AppVariablesCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/app-variables/:id'
                                component={AuthenticationCheck(
                                    AppVariablesShow,
                                )}
                            />
                            (Reports)
                            <Route
                                exact
                                path='/reports'
                                component={AuthenticationCheck(Reports)}
                            />
                            (Currencies)
                            <Route
                                exact
                                path='/currencies'
                                component={AuthenticationCheck(CurrenciesIndex)}
                            />
                            <Route
                                exact
                                path='/currencies/create'
                                component={AuthenticationCheck(
                                    CurrenciesCreate,
                                )}
                            />
                            <Route
                                exact
                                path='/currencies/:id'
                                component={AuthenticationCheck(CurrenciesShow)}
                            />
                            (Fallbacks)
                            <Route
                                exact
                                path='/'
                                component={RedirectIfAuthenticated(Login)}
                            />
                            <Route
                                component={AuthenticationCheck(Error404, true)}
                            />
                        </Switch>
                    </BrowserRouter>
                </div>
            </Provider>
        );
    }
}

export default App;
