import BaseModel from "./BaseModel";
import { Label } from "semantic-ui-react";
const config = require("../config");

class PartnerClubContract extends BaseModel {
    static route = "partner-club-contracts";

    static casts = {
        activatesAt: this.casters.date,
        expiresAt: this.casters.date,
    };

    storePayloadTransformer() {
        return {
            name: this.name,
            activatesAt: this.activatesAt,
            expiresAt: this.expiresAt,
            allocated: this.allocated,
            reserved: this.reserved,
            resalePrice: this.resalePrice,
            used: this.used,
            course: this.course,
        };
    }

    updatePayloadTransformer() {
        return {
            name: this.name,
            activatesAt: this.activatesAt,
            expiresAt: this.expiresAt,
            allocated: this.allocated,
            reserved: this.reserved,
            resalePrice: this.resalePrice,
            used: this.used,
            course: this.course._id,
        };
    }

    get statusLabel() {
        if (this.status === "ACTIVE") {
            return <Label color='green'>Active</Label>;
        }

        if (this.status === "EXPIRED") {
            return <Label color='red'>Expired</Label>;
        }

        if (this.status === "NOT_YET_ACTIVE") {
            return <Label color='blue'>Not yet active</Label>;
        }

        return <Label color='grey'>Unknown</Label>;
    }

    async usage(params = {}) {
        const { data } = await this.constructor
            .http()
            .get(
                `${config.api_url}${this.constructor.route}/${this._id}/usage`,
                params,
            );
        return {
            data: data.data,
            total: data.total,
        };
    }

    static async invoices(params = {}) {
        const { data } = await this.http().get(
            `${config.api_url}${this.route}/invoices`,
            params,
        );
        return data;
    }

    static async invoicesSummary(params = {}) {
        const { data } = await this.http().get(
            `${config.api_url}${this.route}/invoices/summary`,
            params,
        );

        return data.data;
    }

    async storeInvoice(payload) {
        const { data } = await this.constructor
            .http()
            .post(
                `${config.api_url}${this.constructor.route}/${this._id}/invoices`,
                payload,
            );

        return data.data;
    }

    async markAsPaid(id) {
        return this.constructor
            .http()
            .post(
                `${config.api_url}${this.constructor.route}/${this._id}/invoices/${id}/pay`,
            );
    }

    async deleteInvoice(id) {
        return this.constructor
            .http()
            .delete(
                `${config.api_url}${this.constructor.route}/${this._id}/invoices/${id}`,
            );
    }
    async storeAdjustment(payload = {}) {
        const { data } = await this.constructor
            .http()
            .post(
                `${config.api_url}${this.constructor.route}/${this._id}/adjustments`,
                payload,
            );
        return data.data;
    }
}

export default PartnerClubContract;
